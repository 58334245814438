import React, { useEffect, useState } from "react";
import { Button, Dropdown, Space, Typography, Input, Row, Col, Popover, Card, Tooltip, Select, DatePicker, Spin } from "antd";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import dayjs from "dayjs";
import moment from "moment";
// Components
import DataTableComponent from "../DataTableComponent";
import { TRANSACTION_FILTER } from "./constant";

import "./ordersTable.css"
import routes from "../../helpers/routes";
import { Images } from "../../images";
import TransactionResponsive from "./TransactionResponsive";
// Actions
import { actions as transactionAction } from "../../contexts/transaction/actions";
// Dispatch & States
import { useTransactionDispatch, useTransactionState } from "../../contexts/transaction";
// Utils & Constants
import {
  STRATS_CONVERSION, TRANSACTION_STATUS, TRANSACTION_STATUS_CLASSES, TRANSACTION_SORT,
  TRANSACTION_STATUS_COLOR, DOWNLOAD_OPTIONS, REDEMPTION_STATUS, REDEMPTION_STATUS_CLASSES
} from "../../helpers/constants";
import { SEO } from "../../helpers/seoConstant";

const limit = '', offset = '';

const TransactionTable = ({ user, download, isAllOrdersLoading }) => {
  const StratsIcon = <img src={Images.logo} alt="" className="mx-1 w-3 h-3" />
  // Dispatch
  const transactionDispatch = useTransactionDispatch();
  // States
  const { userTransactions, globalTransaction, isTransactionLoading } = useTransactionState();

  const navigate = useNavigate();
  const location = useLocation();

  const currentMonth = dayjs().startOf('month').unix();

  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');
  const dateQuery = searchParams.get('date');
  const [transactions, setTransactions] = useState(userTransactions)
  const [search, setSearch] = useState("")

  const formatter = new Intl.NumberFormat('en-US');
  const formattedNum = (num) => formatter.format(num);
  const defaultDate =  dateQuery ? dayjs.unix(dayjs(dateQuery).startOf('month').unix()) : dayjs.unix(currentMonth);

  useEffect(() => {
    if (user?.commonName && dateQuery) {
      transactionAction.fetchUserTransaction(
        transactionDispatch,
        limit,
        offset,
        user?.commonName,
        dateReturn(dateQuery)
      );
    }
    if (user?.commonName && !dateQuery) {
      const startOfMonth = dayjs().startOf('month').unix();
      const endOfMonth = dayjs().endOf('month').unix();
      const dateArr = [startOfMonth, endOfMonth]
      transactionAction.fetchUserTransaction(
        transactionDispatch,
        limit,
        offset,
        user?.commonName,
        dateArr
      );
    }
  }, [user, dateQuery])

  useEffect(() => {
    let filteredData = userTransactions;

    if (type) {
      filteredData = filteredData.filter((item) => item.type === type);
    }
    if (search) {
      const searchString = String(search).toLowerCase();
      filteredData = filteredData.filter((item) =>
        String(item.assetName).toLowerCase().indexOf(searchString) !== -1
      );
    }

    setTransactions(filteredData);
  }, [userTransactions, type, search]);


  const onDateChange = (date) => {
    const unixTimestamp = dayjs(date).unix();
    const formattedDate = dayjs.unix(unixTimestamp).format('MMMM YYYY');
    navigate(`/transactions?date=${formattedDate}`);
  };

  const dateReturn = (date) => {
    const startDate = dayjs(date).startOf('month').unix();
    const endDate = dayjs(date).endOf('month').unix();
    return [startDate, endDate]
  }

  const Content = ({ data }) => {
    const price = data?.assetPrice || data?.price
    return <div className="min-h-44 h-full" style={{ width: '460px' }}>
      <Card>
        <Row>
          <Col span={6}>
            <img src={data?.assetImage} alt={data?.assetName} className="border w-88 h-88 border-indigo-600 rounded-md" />
          </Col>
          <Col span={8} offset={1}>
            <p className="text-base font-bold text-truncate cursor-pointer" onClick={()=>{handleAssetRedirection(data)}}>{data?.assetName}</p>
            <p style={{ color: '#827474' }} className="font-medium mt-2 min-h-20 cursor-default text-truncate"><Tooltip placement="top" title={data.assetDescription.replace(/<\/?[^>]+(>|$)/g, "")}> {data?.assetDescription.replace(/<\/?[^>]+(>|$)/g, "")} </Tooltip></p>
          </Col>
          <Col span={8} offset={1}>
            {price
              ? <p className="text-right flex justify-end items-center"> <b>$ {price} </b> &nbsp;(<span className="text-[#13188A] font-bold"> {(price) * STRATS_CONVERSION} </span>{StratsIcon}) </p>
              : <p className="text-right text-[#13188A] font-bold text-sm"> No Price Available  </p>}
          </Col>
        </Row>
      </Card>
    </div>
  };

  const handleDetailRedirection = (data) => {
    let route;
    if (data.type === 'Order' && data.sellersCommonName === user.commonName) {
      route = `${routes.SoldOrderDetails.url.replace(":id", data.address ? data.transaction_hash : data.address)}`
    }
    else if (data.type === 'Order' && data.sellersCommonName !== user.commonName) {
      route = `${routes.BoughtOrderDetails.url.replace(":id", data.address ? data.transaction_hash : data.address)}`
    }
    else if (data.type === 'Transfer') { }
    else if (data.type === 'Redemption' && data.to === user.commonName) {
      route = `${routes.RedemptionsIncomingDetails.url.replace(":id", data.redemption_id)
        .replace(":redemptionService", data.redemptionService)}`
    } else if (data.type === 'Redemption' && data.from === user.commonName) {
      route = `${routes.RedemptionsOutgoingDetails.url.replace(":id", data.redemption_id)
        .replace(":redemptionService", data.redemptionService)}`
    } else { }
    route && navigate(route)
  }

  const handleAssetRedirection = (data) => {
    const url = routes.MarketplaceProductDetail.url.replace(':address',data.assetAddress).replace(':name', data.assetName)
    navigate(url)
  }

  const column = [
    {
      title: "#",
      dataIndex: "reference",
      key: "reference",
      width: '80px',
      render: (reference, data) => (
        <p
          id={reference}
          onClick={() => {
            handleDetailRedirection(data)
          }}
          className={`text-[#13188A] hover:text-primaryHover ${data.type === 'Transfer' ? 'cursor-default' : 'cursor-pointer' }`}
        >
          {`#${`${reference}`.substring(0, 6)}`}
        </p>
      ),
    },
    {
      title: <p className="text-center font-bold">Type</p>,
      dataIndex: "type",
      key: "type",
      width: "150px",
      render: (text) => (<p style={{ background: TRANSACTION_STATUS_COLOR[text] }} className={`bg-${TRANSACTION_STATUS_COLOR[text]} min-w-[80px] text-center cursor-default px-2 py-2 rounded-lg text-white`}>{text}</p>),
    },
    {
      title: "Asset",
      dataIndex: "Item",
      key: "Item",
      align: "left",
      width: '150px',
      render: (asset, data) => (
        <Popover className="flex" content={<Content data={data} />} trigger="hover">
          <div className="flex items-center cursor-default">
            <img src={data?.assetImage} alt={data?.assetName} width={24} height={30} 
              className="border w-6 h-8 border-indigo-600 rounded-md object-contain" 
            />
            <span className="ml-1 text-truncate">{data?.assetName}</span>
          </div>
        </Popover>
      )
    },    
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      width: '100px',
      render: (data, { quantity }) => <span>{quantity ? formattedNum(quantity) : '--'}</span>
    },
    {
      title: "Price ($)",
      dataIndex: "price",
      key: "price",
      align: "right",
      width: '100px',
      render: (data, { price }) => <p>{price ? formattedNum(price) : '--'}</p>
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      align: "center",
      width: '150px',
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      align: "center",
      width: '150px',
    },
    {
      title: "Hash",
      dataIndex: "hash",
      key: "hash",
      align: "left",
      width: '150px',
      render: (data, { transaction_hash }) =>{
        return <Tooltip placement="top" title={transaction_hash || ''}>
        <p className="text-[#13188A] hover:text-primaryHover cursor-pointer text-truncate-single-line" >{transaction_hash ? `# ${transaction_hash}` : '--'}</p>
      </Tooltip>}
    },
    {
      dataIndex: "date",
      key: "date",
      width: '150px',
      render: (text,{block_timestamp}) => <p>{moment(block_timestamp).format('L')}</p>,
      title: (
        <div style={{ display: "flex" }}>
          <div className="mt-1.5">{"Date"}</div>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: '150px',
      render: (text, data) => statusComponent(text, data),
    },
  ];

  const statusComponent = (status, data) => {
    status = data.type === "Transfer" ? 3 : status
    const { textClass, bgClass } = data.type === "Redemption" ? REDEMPTION_STATUS_CLASSES[status] : TRANSACTION_STATUS_CLASSES[status] || { textClass: "bg-[#FFF6EC]", bgClass: "bg-[#119B2D]" };
    return (
      <div className={classNames(textClass, "w-max text-center py-1 cursor-default rounded-xl flex justify-start items-center gap-1 p-3")}>
        <div className={classNames(bgClass, "h-3 w-3 rounded-sm")}></div>
        <p>{data.type === 'Redemption' ? REDEMPTION_STATUS[status] : TRANSACTION_STATUS[status]}</p>
      </div>
    );
  };

  const handleChangeSearch = (e) => {
    const value = e.target.value;
    setSearch(value)
  }

  const handleFilter = (val) => {
    navigate(val ? `/transactions?type=${val}` : `/transactions`)
  }

  const metaImg = SEO.IMAGE_META;

  return (
    <Row>
      <Col span={24} className="w-full min-h-[160px] py-4 px-4 md:min-h-[96px] bg-[#F6F6F6] flex flex-col md:flex-row lg:px-14 justify-between items-center mt-6 lg:mt-8">
        <Row className="w-full flex justify-between items-center">
          <Col xs={24} lg={4} className="flex justify-between w-full">
            <Button className="!px-1 md:!px-0 md:ml-5 lg:ml-0  flex items-center flex-row-reverse gap-[6px] text-lg md:text-2xl font-semibold !text-[#13188A] "
              type="link"
              icon={<img src={Images.ForwardIcon}
                alt={metaImg}
                title={metaImg}
                className="hidden md:block w-6 h-6" />}> My Transactions
            </Button>
          </Col>
          <Col xs={24} lg={16} xl={12} className="flex flex-col md:flex-row gap-3 items-center my-2 md:my-0">
            <Row className="w-full flex items-center justify-between">
              <Col xs={24} xl={24}>
                <Row className="w-full md:w-auto md:flex md:justify-between items-center mb-5 mt-4">
                  <Col xs={24} md={7} className="flex justify-center mt-2 md:mt-0">
                    <Select className="block lg:block w-full md:w-4/5 rounded-md mx-auto" onChange={(val) => { handleFilter(val) }} placeholder="Select Type" defaultValue={type || ''}>
                      {TRANSACTION_FILTER.map(({ label, value }) =>
                        <Select.Option value={value}> {label} </Select.Option>
                      )}
                    </Select>
                  </Col>
                  <Col xs={24} md={8} className="flex justify-center mt-2 md:mt-0">
                    <Input className="text-base w-full md:max-w-[400px] h-10 orders_searchbar mx-auto md:mr-3 rounded-md bg-[#F6F6F6]"
                      onChange={(e) => { handleChangeSearch(e) }}
                      value={search}
                      prefix={<SearchOutlined />}
                      placeholder="Search Asset" />
                  </Col>
                  <Col xs={21} sm={22} md={6} xl={7} className="mt-2 md:mt-0 flex justify-center">
                    <div className="border border-slate-300 w-full rounded-lg">
                      <DatePicker onChange={onDateChange}
                        className="w-full"
                        defaultValue={defaultDate}
                        picker="month"
                        disabledDate={(current) => { return current && current > dayjs().endOf('month') }}
                        format={(value) => dayjs(value).format('MMMM YYYY')}
                        allowClear={false}
                      />
                    </div>
                  </Col>
                  <Col xs={1} md={2} className="ml-4 sm:ml-6 md:ml-0 flex justify-center mt-2 md:mt-0">
                    <Dropdown
                      className="customButton"
                      menu={{ items: DOWNLOAD_OPTIONS, onClick: (e) => download(e.key) }}
                      disabled={isTransactionLoading}
                      trigger={['click']}
                    >
                      <Button className="h-[32px] w-[33px] rounded-md border border-[#6A6A6A] flex justify-center items-center">
                        <Space>
                          <DownloadOutlined />
                        </Space>
                      </Button>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={22} className="mx-auto mt-5">
        <div className="flex md:hidden order_responsive">
          {isTransactionLoading ? <Spin className="mx-auto" /> 
          : <TransactionResponsive data={transactions} user={user} />}
        </div>
        <div className="hidden md:block">
          <DataTableComponent
            columns={column}
            data={transactions}
            isLoading={isTransactionLoading}
            pagination={false}
            scrollX="100%"
          />
        </div>
      </Col>
    </Row>
  );
};

export default TransactionTable;
